<template>
  <div class="content-subject-matrix">
    <div class="content-matrix-1to5" v-for="(option, index) in question.subjects" v-bind:key="index">
      <div class="subject-matrix-1to5">
        <div class="subject-label">
          <span :style="colors.splashColor" v-html="replaceMeta(option.label)"></span>
        </div>
        <div class="subject-rating">
          <app-form-rate-active
            v-model="inputValue"
            :items="items"
            item-value="id"
            item-text="id"
            :textColor="colors.actionText"
            :bgColor="colors.actionColor"
            :hoverColor="colors.buttonHover"
            @rate-value="updateValue($event,option._id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { replaceMetadata } from '../../_helpers/metadata'

export default {
  name: 'matrix1to5',
  props: ['question', 'colors'],
  components: {
    'app-form-rate-active': () => import('@binds-tech/binds-design-system/src/components/Form/RateActive')
  },
  data () {
    return {
      inputValue: '',
      activeNext: !this.question.required,
      valueCheck: [],
      value: [],
      items: [
        { id: 1, value: 0 },
        { id: 2, value: 25 },
        { id: 3, value: 50 },
        { id: 4, value: 75 },
        { id: 5, value: 100 }
      ],
      keycode: {
        em: {
          init: '*|q-',
          end: '|*',
          rxstart: '\\*\\|q\\:',
          rxmid: '(.*?)',
          rxend: '\\|\\*'
        },
        span: {
          init: '*|',
          end: '|*',
          rxstart: '\\*\\|',
          rxmid: '(.*?)',
          rxend: '\\|\\*'
        },
        b: {
          init: '**',
          end: '**',
          rxstart: '\\*{2}',
          rxmid: '(.*?)',
          rxend: '\\*{2}'
        },
        i: {
          init: '*',
          end: '*',
          rxstart: '\\*',
          rxmid: '(.*?)',
          rxend: '\\*'
        },
        s: {
          init: '~~',
          end: '~~',
          rxstart: '\\~{2}',
          rxmid: '(.*?)',
          rxend: '\\~{2}'
        },
        u: {
          init: '__',
          end: '__',
          rxstart: '\\_{2}',
          rxmid: '(.*?)',
          rxend: '\\_{2}'
        }
      }
    }
  },
  create () {
    // seta tradução da question
    if (this.question.language) {
      this.$i18n.locale = this.question.language
    }
  },
  mounted () {
    this.question.subjects.forEach(item => {
      this.value.push({ _id: item._id })
    })

    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.submit()
      }
    })
  },
  computed: {
    questionsList () {
      const qs = this.$store.getters['question/getQuestion']
      return qs
    },
    metadata () {
      let data
      if (!this.isPreview) {
        data = this.$store.getters['seedData/getSeedData']
      } else {
        data = this.$store.getters['preview/getSeedData']
      }

      const metadata = data && data.metadata ? data.metadata : {}
      return metadata
    }
  },
  methods: {
    updateValue (rating, id) {
      const optionIndex = this.value.findIndex(item => item._id === id)
      if (optionIndex > -1) {
        if (this.value[optionIndex].rating) {
          this.value[optionIndex].rating = rating
          this.valueCheck[optionIndex].rating = rating
        } else {
          this.value[optionIndex].rating = rating
          this.valueCheck.push(this.value[optionIndex])
        }
      }
      if (this.question.required) {
        this.valueCheck.length >= this.value.length ? this.$root.$emit('show-btn', true) : this.$root.$emit('show-btn', false)
      }
    },
    submit () {
      const send = this.value
      const answeredOptionsCount = this.value.filter(item => {
        return item.rating !== undefined
      })
      let submitSubjects = {}
      answeredOptionsCount.length > 0 ? submitSubjects = { value: send } : submitSubjects = { skip: true }
      this.$emit('value-changed', submitSubjects)
      this.$emit('question-answered', true)
      this.$root.$emit('refresh-footer')
    },
    replaceMeta (str) {
      return replaceMetadata(str, this.metadata, this.answersList)
    }
  }
}
</script>
<style lang="scss">
.content-subject-matrix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .content-matrix-1to5 {
    width: 95%;
    margin-bottom: 3px;
    .subject-matrix-1to5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5px 0;
      .subject-label {
        min-width: 47%;
        max-width: 85%;
        margin-bottom: 10px;
        span {
          color: var(--splashColor);
        }
      }
      .subject-rating {
        width: 50%;
        .rating {
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0;
          .rating__item {
            .rating__button {
              font-size: 20px;
              width: 40px;
              height: 40px;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
// Responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-subject-matrix {
    .content-matrix-1to5 {
      width: 100%;
      .subject-matrix-1to5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        .subject-label {
          width: 97%;
          margin-bottom: 10px;
          padding: 0 5px;
        }
        .subject-rating {
          width: 100%;
          .rating {
            width: 100%;
            padding: 0;
            padding-left: 25px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .rating__item {
              display: flex;
              align-items: center;
              justify-content: center;
              .rating__button {
                width: 37px;
                height: 37px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
